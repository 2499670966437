import { DataContext } from "DataContainer";
import moment from "moment";
import React, { useContext } from "react";
import { Container, Button, Row, Col, TabContent, TabPane, Collapse } from "reactstrap";
import { groupBy } from "views/content";

function Agenda() {
  const [activeTab] = React.useState("1");

  const [open, toggleOpen] = React.useState({
    open: -1,
  });
  const { agenda } = useContext(DataContext);
  const dayWiseAgenda = groupBy("day")(agenda);

  function AgendaCard({ data }) {
    const size =
      document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
        ? true
        : false;
    return data.map((d, index) => (
      <Container>
        <Row>
          <Col lg={1} />
          <Col lg={10} className="text-white  py-4 border-bottom">
            <h4 className="text-600 mt-0 text-white text-primary">
              <b>
                {moment.utc(d.startAt).format("HH:mm")} - {moment.utc(d.endAt).format("HH:mm")}{" "}
              </b>{" "}
            </h4>
            <h4 className="text-400 m-0 pb-2">
              <b>{d.title}</b>
            </h4>
            <h5
              className="text-400 text-white description-text"
              style={{ color: "white !important" }}
              dangerouslySetInnerHTML={{ __html: d.description }}
            />
          </Col>
        </Row>
      </Container>
    ));
  }
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          backgroundImage: `url(${require("assets/images/BG3.png")})`,
          backgroundSize: size ? "100%" : "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
        className="section pb-0"
        data-parallax={true}
      >
        <div className="overlay-primary" />
        <Container></Container>
        <Container>
          <Row>
            <Col md="12">
              <h1 className="text-300 text-white text-left mb-4">EVENT AGENDA</h1>
            </Col>
            <Col md="12">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[1] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[2] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="py-3 mb-5 text-center">
              <Button
                href="/register"
                className="navbar-item-custom text-400 text-dark rounded-0 "
                style={{
                  background: "#e65f04",
                  border: "2px solid #000",
                }}
                size="lg"
              >
                <span
                  style={{
                    color: "#000",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="text-600"
                >
                  REGISTER NOW <i class="fa fa-arrow-right" />
                </span>
              </Button>
            </Col>
            {/* <Col lg={12} className="pt-3 text-center"></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Agenda;
