import React from "react";

// core components
import Navbar from "../components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import TextGrid4 from "components/TextGrid4.js";
import SponsorNew from "components/SponsorNew.js";
import DemoFooter from "../components/Footer.js";

import Agenda from "../components/Agenda.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  // const size =
  //   document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;
  return (
    <div style={{ background: "#000000" }}>
      <Navbar />
      <section id="home" />
      <LandingPageHeader />

      <TextGrid4 />
      <section id="speakers">
        {/* <SpeakerCard /> */}
      </section>

      <section id="agenda">
        <Agenda />
      </section>

      <section id="partners">
        <SponsorNew />
      </section>

      <DemoFooter />
    </div>
  );
}

export default Home;
